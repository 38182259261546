.counsellor-item {
    width: 100% !important;
    position: relative;

    .counsellor-spinner {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 100px;
        top: 0;
        margin-left: auto;

        &::after {
            height: 20px;
            width: 20px;
            border-width: 4px;
        }
    }

    .counsellor-collapsed {
        display: flex;
    }
}