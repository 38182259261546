.register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    width: 90%;

    span {
       text-align: left;
    }
     button {
        margin-top: 10px;
        width: 100% !important;
        min-width: none !important;
     }
}