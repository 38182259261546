.email-message {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-top: 20px;

    button {
        width: 100% !important;
        min-width: none !important;
    }
}