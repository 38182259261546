.clients-table {
    position: relative;
    margin-bottom: 30px;
    min-width: 950px;
    max-width: 1300px;
    width: 100%;

    &.cousnellor-page-table {
        width: 60%;
    }

    &.non-icbc {
        min-width: 950px;
        max-width: 1150px;
        width: 90%;

        &.cousnellor-page-table {
            width: 40%;
        }
    }

    &.non-direct {
        min-width: 950px;
        width: 60%;
        
        &.cousnellor-page-table {
            width: 30%;
        }
    }

    .admin-table.header > span {
        padding: 0px 10px 0px 15px !important
    }

    .buttons-col {
        flex: 300px;
        min-width: 250px;
        max-width: 265px;
    }

    .non-direct-btn {
        min-width: 250px;
        max-width: 265px;
        flex: 245px;
    }

    .table-head-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .legends {
            display: flex;
            align-items: center;
            border: 1px solid #cdcdcd;
            padding: 5px 10px;
            border-radius: 4px;
            width: 280px;
            justify-content: center;
            margin-left: auto;

            &.non-direct {
                width: 330px;
            }

            span {
                font-size: 12px;
                font-weight: 600;
                margin-right: 20px;
            }               

            .legend-item {
                display: flex;
                align-items: center;

                img {
                    margin-right: 5px;
                    width: 15px;
                }
            }
        }
    }

    .table-head {
        font-size: 14px;
        height: 30px;
    }

    .cousnellor-buttons {
        max-width: 165px !important;
        min-width: 165px !important;
    }
}