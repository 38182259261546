.non-direct-clients {
    margin-top: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    
    .deactivated-table {
        box-shadow: none;
        margin: 0;

        &::before {
            display: none;
        }
        .deactivated-summary {
            padding: 0;
            min-height: 30px;

            div {
                margin: 0 !important;
            }

            .table-head {
                font-size: 14px;
                height: 30px;
            }

            div[role=button] {
                top: 17px;
                left: 145px !important; 
                height: 35px;
                width: 35px;
            }
        }
        
        .deactivated-details {
            padding: 0;
        }   
    }
}