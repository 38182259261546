.counsellor-legends {
    display: flex;
    align-items: center;
    border: 1px solid #cdcdcd;
    padding: 5px 10px;
    border-radius: 4px;
    width: 730px;
    justify-content: space-between;
    margin-left: auto;

    span {
        font-size: 12px;
        font-weight: 600;
        text-align: left
    }               

    .legend-item {
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }
}