.container__search, .container__rates {
  text-align: center;
  padding: 3rem 1rem; 
}

.admin-homepage, .counsellor-page, .counsellor-homepage {
  padding: 1rem 30px; 
}

.search-input.standard-margin {
  margin-bottom: 0 !important;
}
.counsellor-search {
  position: relative;
}

.counsellor-search .client-input{
  flex: 50% 0;
}
.counsellor-search button {
  position: absolute;
  right: 2rem;
}

.counsellor-search .client-input .MuiInput-root-188 {
 max-width: 300px !important;
}

.cell {
  padding: 4px 10px 4px 15px !important
}

.action-cell {
  min-width: 255px;
  width: 10%;
}
.profile-cell {
  width: 15%;
}

.rate-cell {
  width: 41%;
}

.source-cell {
  width: 25%;
}

.MuiTableCell-root {
  color: rgb(95, 95, 95);
  font-weight: 500 !important;
} 

@media (max-width: 767px) {
  .appointment-search {
    flex-direction: column;
  }

  .appointment-search > button {
    margin-top: 20px !important;
    width: calc(100% - 41px) !important;
  }

  .client-input {
    margin-left: 0 !important;
    min-width: 100%;
    margin: 0 !important;
  }

  .client-input > div {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .search-input {
    min-width: calc(100% - 41px) !important;
    margin-top: 20px !important; 
  }
}

