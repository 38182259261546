.rate-row {
    .rate {
        &-select {
            width: 157px;
            &:after, &:before {
                display: none !important;
            }

            &.non-direct {
                svg {
                    display: none !important 
                }
            }
            
        }



        &-input {
            margin-left: 20px;
            width: 70px;
            
            label {
                top: -5px !important;
                right: -6px !important;
                left: auto !important;
                font-size: 12px !important;
                
                & + div {
                    margin-top: 0 !important;
                }
            }

                
        }
    }

    .save-button, .deactivate-button {
        min-height: 20px !important;
        width: 70px;
        height: 23px;
        font-size: 10px !important;
        min-width: 20px !important;
        margin-left: 5px;
    }

    .deactivate-button {
        color: red !important;
        align-self: flex-end;

    }

    .MuiTableCell-root-182 {
        padding-left: 16px;
    }

    .action-cell {
        text-align: right !important;
    }

    &.inactive .cell {
        color: rgb(164, 164, 164) !important;

        div, span {
            color: rgb(164, 164, 164) !important;
        }

        .MuiSwitch-bar-246 {
            background-color: rgb(164, 164, 164) !important
        }
    }
}

