.client-buttons {
    width: 100%;
    display: flex;
    margin: 0;

    .deactivate-btn,.save-btn {
        min-width: min-content;
        width: 20px;
        min-height: 15px;
        height: 18px;
        box-shadow: none;
        padding: 0 !important;
        margin-right: 0 !important;
    }

    .deactivate-btn {
        color: #ED0E51F2;
        margin-left: 15px;   
        width: 80px;

        &.request {
            background: #0781F1F2;
            color: rgb(255, 255, 255)
        }
    }

    .turn-alerts-btn {
        color: #ffffff;
        background: #a500a5 !important;
        min-width: min-content;
        width: 120px !important;
        padding: 0 !important;
        min-height: 15px;
        height: 18px;
        margin-left: 15px !important;  
        box-shadow: none;

        &.black {
            background: #000000e3 !important;
        }
    }
}