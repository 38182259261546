.alert {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 15%;
    max-width: 200px;
    min-width: 140px;
    min-height: 180px;
    align-items: flex-start;
    
    position: relative;
    
    .alert-wrapper {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 10px;
        width: 100%;
        min-height: 100%;
        border: 1px solid #BDBDBD;
        border-radius: 5px;
        position: absolute;
        z-index: 100;
        background: white;
    }

    .alert-head {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #2C333F;
        img {
            width: 25px;
            margin-right: 10px;
        }
    }
    .clients-list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;

        .empty-alert {
            width: 95px;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
        }

        .client-name {
            display: flex;
            margin: 0;
            color: #2C333F;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 3px;
        }
    }
}