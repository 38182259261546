.counsellor-list {
  flex-direction: column;
}

.counsellor {
  margin: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%) !important;
}

.calendar__list-item {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 0.7rem;
  overflow: hidden;
  align-self: stretch;
  flex: 1;
}

.calendar__list-item .counsellor-name {
  cursor: pointer;
}

.calendar__list-item span {
  font-weight: 600;
}

.calendar__list-item .counsellor-input {
  margin-left: 10px !important;
}
.calendar__list-item input {
  width: 40px;
  text-align: center;
}

.counsellor-button {
  min-height: 10px !important;
  height: 25px;
  min-width: 80px !important;
  width: 80px;
  font-size: 11px !important;
}