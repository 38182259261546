.container__login { 
  text-align: center;
  padding: 2rem 1rem;
  width: 80%;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 5%;
  position: relative;
}
.container__login img { 
  margin: 0 auto; 
}
.forgot-btn {
  right: 25px;
  position: absolute !important; 
  text-transform: none !important; 
  border: none;
  background: none;
  font-size: 14px;
  margin-bottom: 10px;
  padding-bottom: 3px;
  color: #34aee2;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  bottom: 0;
}

@media (max-width: 767px) {
  .forgot-btn {
    right: calc(50% - 64px);
  }
}