.email-form {
   display: flex;
   flex-direction: column;
   justify-content: center;
   justify-content: center;
   margin-top: 20px;
   width: 90%;

   span {
      text-align: left;
   }

   button {
      margin-top: 15px;
      width: 100% !important;
      min-width: none !important;
   }
}