.client__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  overflow: hidden;
  cursor: pointer;
}

.client__list-item img {
  height: 5rem;
  margin: 0 1rem;
}

.client__list-item > .avatar {
  font-size: 0.5rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  line-height: 1.5rem;
  border-radius: 0.75rem;
}

.client__list-item ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.client__list-item h1 {
  margin: 0;
  flex-shrink: 0 !important;
}

.client__list-item > ul > li {
  margin: .5rem;
  text-overflow: hidden;
  overflow: hidden;
}

.client__list-item > ul > li > svg {
  color: #60a9ca;
}