.bad-fit-container{
    &.grey {
        color: rgba(0, 0, 0, 0.5);
    }
    
    .notes-input {
        width: calc(100% - 230px) !important;
        div {
            &::after, &::before {
                display: none;
            }
        }
        input {
            font-size: 10px;
            width: 100% !important;
        }
    }
}
