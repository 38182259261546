.task-list {
    padding-bottom: 25px;
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 8px ;
    margin-top: 10px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgb(228, 224, 224);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color:rgb(146, 146, 146)
    }
    .task-date {
        display: flex;
        margin: 5px 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        justify-content: flex-end;
    }
}