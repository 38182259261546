.task-group {
    min-width: 180px;
    max-width: 500px;
    width: 35%;
    min-height: 450px;
    padding: 0 15px;
    margin-top: 20px;
    position: relative;
    background: white;

    &.wide-group {
        width: 55%;
        max-width: none;
    }

    .task-group-wrapper {
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 20px;
        min-height: 100%;
        width: calc(100% - 30px);
        border: 1px solid #BDBDBD;
        border-radius: 5px;
        background: white;

        .empty-smile {
            width: 30%;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%);
        }
    }
    &.expanded .task-group-wrapper{
        transition: all 0.3s;
        z-index: 10;
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    }

    h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        font-size: 16px;
        color: #2C333F;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .collaple-list-btn {
        position: absolute;
        width: 24px;
        height: 24px;
        bottom: 3px;
        right: 85px;

        img {   
            width: 16px;
        }
    }

    .group-wrapper {
        width: 100%;
        .group-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}