.request-button-wrapper {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-button {
        width: 110% !important;
        box-shadow:none !important;
        font-size: 9px;
        padding: 0 !important;
        color: rgb(255, 0, 0) !important;

        &.active {
            background: #0781F1F2 !important;
            color: rgb(255, 255, 255) !important
        }
    }
}
