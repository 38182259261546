.appointment__list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  overflow: hidden;
  cursor: pointer;
}

.appointment__list-item img {
  height: 5rem;
  margin: 0 1rem;
}

.appointment__list-item ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.appointment__list-item h1 {
  margin: 0;
  flex-shrink: 0 !important;
}

.appointment__list-item > ul > li {
  margin: .5rem;
}

.appointment__list-item > ul > li > svg {
  color: #60a9ca;
}

.layout__container .export-checkbox {
  height: 30px;
  width: 30px;
}
.layout__container {
  padding: 0 1rem;
}
.select-all-checkbox {
  height: 30px !important;
  width: 30px !important;
  margin-right: 5px !important;
}
.single-export-btn {
  min-height: 20px !important;
  width: 70px;
  height: 20px;
  font-size: 10px !important;
  min-width: 20px !important;
  margin-right: 50px !important;
}

.export-all-btn {
  min-height: 20px !important;
  width: 110px;
  height: 30px;
  font-size: 10px !important;
  min-width: 20px !important;
  margin-left: auto !important;
  margin-right: 50px !important;
  text-align: center !important;
  padding: 0 !important;
}
.payment-source-input input{
  font-size: 12px;
  padding: 0;
  width: 200px;
}

.payment-source-input input:disabled{
  color: rgba(0, 0, 0, 0.87)
}

.payment-source-input > div::before{
  display: none;
}

.payment-source-select {
  margin: 0 !important;
}

.payment-source-select {
  margin: 0;
}

.payment-source-select div {
  font-size: 12px !important;
}

.payment-source-select div:before, .payment-source-select div:after {
  display: none;
}

.deactivated-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  background-color: #b2b2b2;
  color: #424242;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  position: absolute;
  right: 200px;
  top: 50%;
  transform: translate(0, -50%);
}