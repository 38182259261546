.payment-status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 15px;
    background: rgb(255, 132, 31);
    font-size: 80%;
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: 5px;

    &.full-paid {
        background: rgb(1, 165, 1)
    }
}

.section-head {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    color: #2C333F;
    margin-bottom: 0;
    margin-top: 0;
    
    &.counsellor-name {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.11);
    }
}

.show-more-button {
    width: 110px;
    min-height: 30px !important;
    height: 32px !important;
    border-radius: 5px !important;
    position: absolute !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0;
    right: 0;
    bottom: -45px;
    text-align: center;
    font-size: 12px !important;
}

.up-button {
    position: absolute !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 35px !important;
    height: 35px !important;
    right: -200px;
    left: 0;
    bottom: -47px;
    
    img {
        width: 25px;
    }
}

.loading {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
}

//spinner
.lds-dual-ring {
    display: inline-block;
    position: absolute;
    top: calc(50% - 70px);
    left: calc(50% - 50px);
    width: 80px;
    height: 80px;

    &.admin {
        position: relative;
        margin-top: 40px;
    }
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 70px;
    height: 70px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(0, 0, 0);
    border-color: #f50057 transparent #f50057 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin flexible-top($elementHeight) {
    top: ($elementHeight / (-2));
}

.form-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    position: relative;
    img {
        position: absolute;
        right: 10px;
        top: 28px;
        width: 20px;
        cursor: pointer !important;

        &:hover + span {
           opacity: 1;
           visibility: visible !important;
        }
    }

    span {
        color: #525962;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        
        &.input-label {
            text-align: left;
        }
        
        &.invalid-message {
            visibility: hidden !important;
            position: absolute;
            transition: 400ms;
            opacity: 0;
            font-size: 12px;
            box-shadow: 0px 0px 10px 0px #00000040;
            color:rgba(255, 0, 0, 0.623);
            right: 0;
            top: -90%;
            padding: 10px;
            background-color: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.432);
            border-radius: 4px;
            z-index: 0;
            &.small {
                top: -15px;
            }
        }
    }

    .description {
        font-size: 12px;
        text-align: left;
        color: #525962be;
    }
    
    input {
        background: #FFFFFF;
        border: 1px solid #C5D0DE;
        outline: none;
        border-radius: 4px;
        height: 30px;
        font-size: 16px;
        padding-left: 10px;

        &.invalid {
            border-color: rgb(255, 0, 0);
            color: rgba(255, 0, 0, 0.767)
        }
    }
}

.show-more-alert {
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    font-size: 11px;
    color: rgb(0, 0, 255);
    right: 20px;
    bottom: 10px;

    &:hover {
        color: rgb(0, 0, 175);
    }

    &:disabled {
        color:rgba(0, 0, 0, 0.253);
        cursor: default;
    }
}

.collaple-list-btn {
    position: absolute !important;
    width: 24px !important;
    height: 24px !important;
    bottom: 3px;
    right: 85px;

    img {   
        width: 16px;
    }
}