.direct-clients {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;

    .deactivated-table {
        box-shadow: none;
        margin: 0;
        width: fit-content;
        margin-bottom: 10px;
        &::before {
            display: none;
        }
        .deactivated-summary {
            padding: 0;
            min-height: 30px;

            &.other-clients div[role=button] {
                left: 235px !important;
            }

            div {
                margin: 0 !important;
            }

            .table-head {
                font-size: 14px;
                height: 30px;
            }

            div[role=button] {
                top: 17px;
                left: 190px !important; 
                height: 35px;
                width: 35px;
            }
        }


        
        .deactivated-details {
            padding: 0;
        }   
    }
}