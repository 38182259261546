.task-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    p {
        margin: 6px 0;
    }

    &.direct {
        font-weight: 600;
    }
}
.task-checkbox {
    width: 25px !important;
    height: 25px !important;

    svg {
        width: 20px;
        height: 20px;
    }
}