body {
  font-size: 12px;
  font-family: sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  margin: 1rem 0;
  font-size: 12px;
}

.text-center {
  margin: 0 auto;
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

.text-light {
  color: #efefef;
}

.text-dark {
  color: #333;
}

.avatar {
  min-width: 3rem;
  min-height: 3rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  color: #fff;
  margin: 0 1rem !important;
  text-transform: uppercase;
  font-weight: normal;
}

a.active {
  color: #f50057;
}