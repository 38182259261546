.form__search {
  margin: 0 auto !important;
  min-width: 25rem !important;
}

.form__login {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
}

.form__login > .MuiFormControl-marginNormal-39 {
  margin: 1rem;
}

.form__autocomplete {
  position: absolute;
  z-index: 10;
  transition: opacity 200ms ease-in-out;
}

.form__autocomplete  .layout__container {
  padding: 0 0.5rem;
  max-width: 50rem;
  min-width: 0;
}

@media (max-width: 767px) {
  .form__login {
    flex-direction: column;
  }

  .form__login > .MuiFormControl-marginNormal-39 {
    margin-top: 0;
    margin-bottom: 10px;
  }
}