.table-buttons {
    margin-left: auto;
    margin-right: 10px;

    button {
        min-height: 20px;
        height: 20px;
        min-width: 76px;
        width: 76px;
        font-size: 9px;
    }
    .good-fit-btn {
        background-color: #5DA008 !important;
        margin-right: 10px;
    }

    .save-btn {
        margin-right: 10px;
        min-width: 20px;
        width: 35px;
    }
}