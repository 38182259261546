.bad-fit-table {
    position: relative;
    width: 100%;
    margin-bottom: 70px;

    .bad-fit-head, .invoice-line-item {
        position: relative;

        .bad-fit-checkbox {
            position: absolute;
            left: -1.6rem;
            width: 25px !important;
            height: 25px !important;
            top: 3px;
            border: none;
            padding: 0;

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
}