.group-task-list {
    padding: 10px 0;
    padding-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    
    .group-list {
        margin: 0 auto;
        width: 80%;
        min-width: 900px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}