.client-tale-item {
    .cell {
        height: 24px;
        
        &.focus-field {
            cursor: text;
        }

        .approved-checkbox {
            width: 30px;
            height: 30px;

            svg {
                width: 65%;
            }
        }

        .date-input {
            cursor: pointer;

            div {
                &::after, &::before {
                    display: none;
                }
            }
            input {
                cursor: pointer;
                padding: 0;
                font-size: 10px;
                height: 20px;
                &:disabled {
                    color: rgba(0, 0, 0, 0.87);
                    cursor: default
                }
            }
        }

        .table-input {
            div {
                &::after, &::before {
                    display: none;
                }
            }
            input {
                width: 25px;
                font-size: 10px;
                &:disabled {
                    color: rgba(0, 0, 0, 0.87)
                }
            }
        }

        .table-input-wrapper {
            display: flex;
            align-items: center;
    
            & input {
                position: relative;
                bottom: -0.5px
            }
        }
    }
    .payment-source-select {
        margin: 0;
        
        div {
            font-size: 10px;

            &:before, &:after {
                display: none;
            }
        }
    }
    

    &.red .cell {
        background: #ff004c;
    }
    &.pink .cell {
        background: #E79EA6ED;
    }
    &.yellow .cell {
        background: #F8EE0C8F;
    }
    &.grey .cell {
        background: #BDBDBD;
    }

    .approval-date {
        font-size: 11px;
    }
    .buttons-col {
        background: none !important;
        min-width: 240px;
        max-width: 240px;
        flex: 300px;
    }
}

