.email-pop-up {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.623);
    backdrop-filter: blur(0.5px);
    top: 64px;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 100;

    form {
        background: rgb(255, 255, 255);
        margin-top: 20px;
        display: flex;
        min-height: 420px;
        min-width: 600px;
        height: 34vh;
        width: 30vw;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px #00000040;
        display: flex; 
        justify-content: center;
        position: relative;

        .icon-button {
            display: flex;
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
        }

        .close-button {
            top: 10px;
            right: 10px;
        }

        .send-btn {
            position: absolute;
            right: 20px;
            bottom: 20px;
            border-radius: 5px;
        }

        .form-wrapper {
            width: 90%;
            margin-top: 15px;

            .file-name  {
                margin-top: 15px;
                text-align: left;
                .file-head {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0em;
                    color: #00000059;
                }

                .file {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #2c333fd2;
                }
                
                img {
                    margin-right: 10px;
                }
            }

            .input-wrapper {
                display: flex;
                flex-direction: column;
                margin-top: 15px;
                min-width: 300px;
                width: 80%;
                position: relative;

                .textarea-wrapper {
                    padding-top: 5px;
                    padding-left: 8px;
                    width: 100%;
                    height: 150px;
                    outline: none;
                    resize: none;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    overflow-y: auto;
                    overflow-x: hidden;
                    font-family: monospace;
                    font-size: 13px;
                    text-align: left;
                    scroll-behavior: smooth;
                }

                .input-head {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    color:#2c333fd5;
                    text-align: left;
                }

                img {
                    width: 19px;
                }

                input {
                    padding-left: 8px;
                    width: 100%;
                    height: 25px;
                    outline: none;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.12)
                }
    
                textarea {
                    border: none;   
                    display: block;
                    height: auto;                                                                                                                                                                                                             ;
                    outline: none;
                    width: 100%;
                    resize: none;
                    padding-left: 0;
                }
                
                .delete-btn {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: 0;
                    top: 23px;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
                
                .save-btn {
                    position: absolute;
                    right: 10px;
                    bottom: 8px;
                    font-size: 10px;
                    min-height: 20px !important;
                    min-width: 60px !important;
                    padding: 3px;
                }
            }
            
        }
    }
}

