.invoice__container > div {
  padding: 5% 3%;
  padding-top: 1%;
}

.invoice__container > h1 {
  margin: 2rem 0 1rem 0;
}

.invoice__meta-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1;
  min-height: 75px;
}

article.invoice-meta {
  position: relative;
  border: 1px solid #cdcdcd;
  padding: 0.5rem;
  text-align: left;
  margin: 0.5px;
  flex-basis: 19%;
  flex-grow: 1;
}

article.invoice-meta > .hover__menu {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  height: auto;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  padding: 0.5rem;
}

article.invoice-meta > .hover__menu > h2 {
  margin-bottom: 0.25rem;
}

article.invoice-meta:hover > .hover__menu {
  opacity: 1;
}

.hover__menu > button {
  font-size: 10px;
  padding: 0;
  margin: 0;
  min-width: 2rem;
  min-height: 1.5rem;
}

.hover__menu .save-button {
  position: absolute;
  font-size: 9px !important;
  top: 5px;
  right: 0;
  min-height: 20px;
  min-width: 40px;
  padding: 2px 0 0 0 
}

.hover__menu > h2 {
  margin: 0;
  font-size: 10px;
}

.hover__menu > ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.hover__menu > ul > li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 100%;
}
.hover__menu > ul > li > .hover__menu-input {
  max-width: 100%;
}

article.invoice-meta > h2 {
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 10px;
  text-transform: uppercase;
}
article.invoice-meta > span {
  font-size: 10px;
}

.invoice__line-items {
  margin: 1rem 0;
  position: relative;
}

.invoice__line-items > section > article > .add-button {
  display: none;
  font-size: 10px;
  min-width: 60px;
  width: 70px;
  min-height: 30px;
  height: 30px;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.invoice__line-items:hover > section > article > .add-button {
  display: inline-flex
}


.invoice__line-items > section {
  position: relative;
}

article.invoice-line-item {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

article.invoice-line-item .export-checkbox {
  border: none;
  position: absolute;
  background: none;
  left: -1.8rem;
  width: 15px;
  height: 15px;
}

article.invoice-line-item > span {
  border: 1px solid #cdcdcd;
  display: flex;
  align-items: center;
  padding: 0 0.45rem;
  height: 28px;
  text-align: left;
  font-size: 10px;
  margin: 0.5px;
  background: #fff;
}

.invoice-line-item-container {
  position: relative;
}

.invoice-line-item .empty, .invoice-meta.empty{
  background-color: #ff5e5e
}

.invoice-line-item-container > .hover__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;
  background-color: #efefef;
  transition: opacity 200ms ease-in-out;
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 1px;
  padding: 0.25rem;
  border: 1px solid #cdcdcd;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.invoice-line-item-container:hover > .hover__menu {
  opacity: 1;
}

article.invoice-line-item.footer > span,
article.invoice-line-item.header > span {
  font-weight: bold;
}

article.invoice-line-item.right {
  justify-content: flex-end;
}

article.invoice-line-item.sub-total {
  margin-left: auto;
  max-width: 25%;
}

.invoice__toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0.5rem auto;
}

.invoice__toolbar > button {
  margin: 0 2px;
  font-size: 10px;
}

.invoice-counsellor__menu {
  max-height: 0;
  opacity: 0;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
  display: block;
}

.invoice-counsellor__menu.active {
  opacity: 1;
  max-height: 100vh;
  margin-bottom: 1rem;
}

.close-button {
  font-size: 10px !important;
  margin-bottom: 1rem !important;
  min-width: auto !important;
}

.delete-button {
  min-height: 10px !important;
  height: 25px;
  width: 70px;
  min-width: 50px !important;
  font-size: 10px !important;
  position: absolute !important;
  right: 0;
  bottom: -30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.misc-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.misc-header img{
  margin-left: 12px;
  margin-right: 5px;
}

.misc-header .mics-legend,.misc-header .future-paid-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  height: 28px;
  width: 260px;
}

.invoice-total {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.invoice-line-item-container .counsellor-checkbox {
  position: absolute;
  left: -1.3rem;
  width: 25px !important;
  height: 25px !important;
  top: 0
}

.counsellor-checkbox svg{
  width: 15px;
  height: 15px;
}



.future-paid-list {
  margin-bottom: 20px !important;
}

.outstanding-items, .future-paid-list {
  width: 98%;
  margin: 0 auto;
  position: relative;
}
.future-paid-list h1, .outstanding-items h1{
  display: flex;
  position: absolute;
  top: -45px;
  left: 0
}

.outstanding-items {
  margin-bottom: 60px !important;
}

/* export pdf styles */
.k-pdf-export > div {
  padding: 1% 4%;
}

.k-pdf-export .invoice__meta-container {
  min-height: 47px;
}

.logo {
  display: none;
  max-width: 100px;
  position: absolute;
  left: 0;
  top: -15px
}

.k-pdf-export .logo {
  display: block;
}

.k-pdf-export .invoice__header {
  position: relative;
}

.k-pdf-export .invoice__header h1 {
  font-size: 16px;
  font-weight: 800 !important;
}

.k-pdf-export .counsellor-checkbox {
  display: none;
}

.k-pdf-export button {
  display: none;
}


.payment-statuses-wrapper {
  margin-left: auto;
  display: flex;
}

.outstanding .outstanding-name {
  position: relative;
}

.outstanding .legend-img {
  position: absolute;
  right: 10px;
  height: 13px;
}

.checked-img {
  position: absolute;
  right: 10px;
  width: 16px;
}

.client-invoice-item, .client-description {
  width: calc(33.3% - 17.4px);
  flex: none;
}

.fee-minus {
  width: 7px;
  position: relative;
  top: 1px;
  margin-right: 1.5px;
}