.flex {
  display: flex !important;
  align-items: center;
}

.wrap {
  flex-wrap: wrap !important;
}

.stretch {
  align-self: stretch;
}

.standard-margin {
  margin: 1rem !important;
}

.small-margin {
  margin: 0.5rem !important;
}

.flex-6 { flex: 6%; }
.flex-7 { flex: 7%; }
.flex-12-5 { flex: 12.5%; }
.flex-20 { flex: 20%; }
.flex-25 { flex: 25%; }
.flex-37-5 { flex: 33%; }
.flex-50 { flex: 50%; }
.flex-62-5 { flex: 60%; }
.flex-75 { flex: 75%; }
.flex-87-5 { flex: 87.5%; min-width: 87.5% !important; }
.flex-100 { flex: 100%; }

.max-37-5 { max-width: 37.5%; }
.max-20 { max-width: 20%; }

.nav__separator {
  margin-left: auto;
}

.layout__full-width-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.layout__container {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  flex: 1;
  /* width: 100%;
  max-width: 64rem;
  min-width: 55rem;  */
  padding: 1rem;
}

.layout__call-to-action {
  display: flex;
  justify-content: space-between;
  margin: 1rem 3rem;
}

.layout__call-to-action p {
  flex-grow: 10;
}

.layout__services {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.layout__services article {
  flex: 25%;
  max-width: 25%;
  margin: 0 0.5rem;
}

.layout__footer {
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 10px;
}

.layout__toolbar p {
  margin: 0 1rem;
}

.layout__toolbar a {
  border-radius: 0;
}

.layout__toolbar button.active {
  border-bottom: 1.5px solid #f50057;
  color: #f50057;
}

.background__default {
  background-color: #ffffff;
  background-image: url(https://www.razorsharpconsulting.com/wp-content/uploads/2018/02/company.jpg);
  background-size: cover;
  background-attachment: scroll;
}

@media only screen and (max-width: 700px) {
  .flex-50 { flex: 100% }

  .wrap-sm {
    flex-wrap: wrap !important;
  }

  .layout__container {
    max-width: 100%;
    padding: 0 0.5rem !important;
  }

  .layout__call-to-action {
    margin: 1rem;
  }

  .layout__services {
    flex-flow: row wrap;
  }

  .layout__services article {
    flex: 48%;
    max-width: 48%;
    margin: 1%;
  }
}

.settings-btns {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  flex-direction: column;
  max-height: 35px;
  overflow: hidden;
  border-radius: 4px;
  transition: 0.2s ;
}
.settings-btns button {
  text-transform: none !important;
  justify-content: flex-start;
}
.settings-btns:hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px #00000040;
  max-height: 320px;
}

.settings-btn {
  text-transform: none !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: left !important;
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.welcome-message {
  margin-right: 130px !important;
}