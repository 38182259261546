.change-phone {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.623);
    backdrop-filter: blur(0.5px);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 1000000;

    form {
        background: rgb(255, 255, 255);
        margin-top: 120px;
        display: flex;
        max-height: 250px;
        min-width: 350px;
        height: 210px;
        // height: 30vh;
        width: 25vw;
        max-width: 300px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px #00000040;
        display: flex; 
        justify-content: center;
        position: relative;

        img {
            position: absolute;
            right: 10px;
            top: 28px;
            width: 20px;
            cursor: pointer;
    
            &:hover + span {
               display: block; 
            }
        }
        .close-button {
            display: flex;
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .form-wrapper {
            width: 85% !important;
            display: flex;
            flex-direction: column;

            .form-head {
                margin-bottom: 0;
                color: #2C333F;
                font-weight: 600;
                font-size: 23px;
                line-height: 150%;
            }

            .submit-button {
                margin-top: 20px;
            }
        }
    }
}

