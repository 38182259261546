.bad-fit {
    margin-top: 60px;
    
    .bad-fit-panel {
        box-shadow: none;
        margin: 0 !important;

        .bad-fit-summary {
            padding: 0;
            min-height: 30px;
            max-height: 50px;
            position: relative;

            .bad-fit-spinner {
                position: absolute !important;
                top: 3px;
                left: 150px;

                &::after {
                    height: 20px;
                    width: 20px;
                    border-width: 4px;
                }
            }
        }

        div[role="button"][aria-hidden="true"] {
                top: 25px;
                left: 110px !important; 
                height: 35px;
                width: 35px;
            }
    }
}